import request from '@/utils/request'

export function diaryPage(params) {
    return request.get('/diary/diary/page', { params })
}
export function addLike(params) {
    return request.get('/diary/diary/addLike', { params })
}
export function cancelLike(params) {
    return request.get('/diary/diary/cancelLike', { params })
}
export function addCommentApi(params) {
    return request.post('/diary/commentApp/addComment', params)
}
export function deleteCommentApi(params) {
    return request.get('/diary/commentApp/delete', { params })
}
export function addDiaryApi(params) {
    return request.post('/diary/diary/addDiaryText', params)
}
export function deleteDiaryApi(params) {
    return request.get('/diary/diary/delete', { params })
}
export function deleteFileApi(params) {
    return request.get('/diary/diary/deleteVideo', { params })
}