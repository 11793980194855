<!-- eslint-disable max-len -->
<template>
  <div class="comment-section" style="background-color: #8EC5FC;
background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
">
    <UserModal :visible="showUserModal" :userId="selectUserId" @close="showUserModal = false"></UserModal>

    <div class="comments">
      <div v-for="(item, index) in commentList" :key="index" class="comment-item">
        <div class="comment-info">
          <div class="comment-user"><a href="#" style="color: inherit;text-decoration: none;" @click="openUserModal(item.userId)">{{ item.commentUser }}</a></div>
          <div class="comment-details">
            <span class="comment-time">{{ item.commentTime }}</span>
            <span class="comment-ip">{{ item.address }}</span>
          </div>
        </div>
        <div @click="toggleReplyInput(item)" class="comment-content">{{ item.content }}</div>
        <div v-if="item.replyUser" class="reply-to-info">回复 <a href="#" style="color: inherit;text-decoration: none;" @click="openUserModal(item.replyUser)">{{ item.replyUsername }}</a></div>
          <Poptip v-if="item.userId === currentUser.userId"
        confirm
        title="确认删除?"
        @on-ok="deleteComment(item)"
        >
            <Button type="dashed" style="margin-top: 5px;">删除</Button>
        </Poptip>
        <div v-if="showReplyInput === item.commentId" class="reply-input">
          <textarea v-model="newReply" rows="2" placeholder="请输入回复内容..."></textarea>
          <div class="emoji-picker-wrap">
            <button icon="ios-search" @click="showPicker = !showPicker">表情</button>
            <div v-if="showPicker">
              <emoji-mart :set="emojiSet" :showSearch="false" :i18n="i18nConfig" :showPreview="false"  @select="addEmojiForReply"></emoji-mart>
            </div>
          </div>
          <div class="reply-to-info">回复 <span class="reply-to">{{ item.commentUser }}</span></div>
          <button @click="addReply(item)">回复</button>
          <button @click="showReplyInput=null">取消</button>
        </div>
      </div>
    </div>
    <div class="comment-input" v-if="isShowInputLocal">
      <textarea ref="commentTextarea" v-model="newComment" rows="3" placeholder="请输入评论内容..."></textarea>
      <div class="emoji-picker-wrap">
        <button @click="showPicker = !showPicker">表情</button>
        <div v-if="showPicker">
          <emoji-mart :set="emojiSet" :showSearch="false" :i18n="i18nConfig" :showPreview="false"  @select="addEmojiForNew"></emoji-mart>
        </div>
      </div>
      <button @click="addComment">发表评论</button>
      <button @click="cancelComment()">取消</button>
    </div>
  </div>
</template>
<script>
import { Picker } from 'emoji-mart-vue'
import UserModal from '@/components/user/UserModal.vue'
import { addCommentApi, deleteCommentApi } from '@/api/diary.js'

export default {
    components: {
        'emoji-mart': Picker,
        UserModal,
    },
    props: {
        commentList: {
            type: Array,
            default: () => [],
        },
        diaryId: {
            type: String,
            default: '',
        },
        isShowInput: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isShowInput: {
            handler(newVal) {
                this.isShowInputLocal = newVal
            },
            immediate: true, // 在watcher创建时立即以当前值触发回调
        },
        isShowInputLocal: {
            handler(newVal) {
                this.$emit('update:isShowInput', newVal)
            },
            immediate: true, // 在watcher创建时立即以当前值触发回调
        },
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    },
    data() {
        return {
            isShowInputLocal: false,
            selectUserId: '',
            showUserModal: false,
            currentUser: {},
            newComment: '',
            newReply: '',
            showReplyInput: null,
            emojiSet: 'google',
            showPicker: false,
            i18nConfig: {
                search: '搜索',
                clear: '清除搜索',
                notfound: '未找到表情符号',
                skintext: '选择你的肤色',
                categories: {
                    search: '搜索结果',
                    recent: '最近使用的表情符号',
                    smileys: '笑脸与人物',
                    people: '人物与身体',
                    nature: '动物与自然',
                    foods: '食品与饮料',
                    activity: '活动',
                    places: '旅游与地点',
                    objects: '物品',
                    symbols: '符号',
                    flags: '旗帜',
                    custom: '自定义表情符号',
                },
                categorieslabel: '表情符号分类',
                emoji: '表情符号',
                skintones: {
                    1: '默认肤色',
                    2: '浅肤色',
                    3: '中等肤色',
                    4: '深肤色',
                },
            },
        }
    },
    methods: {
        openUserModal(userId) {
            this.selectUserId = userId
            this.showUserModal = true
        },
        cancelComment() {
            this.isShowInputLocal = false
            this.$emit('change', this.isShowInputLocal)
        },
        addEmojiForReply(emoji) {
            this.newReply = this.newReply + emoji.native
        },
        addEmojiForNew(emoji) {
            this.newComment = this.newComment + emoji.native
        },
        addComment() {
            if (this.newComment.trim() !== '') {
                let content = this.newComment
                const commentObj = { commentUser: this.currentUser.username, content, replyUser: null, userId: this.currentUser.userId }
                addCommentApi({
                    diaryId: this.diaryId,
                    ...commentObj,
                }).then(res => {
                    const comment = res.retVal
                    comment.commentUser = this.currentUser.username
                    this.commentList.push(comment)
                    this.newComment = ''
                    this.showPicker = false
                    this.isShowInputLocal = false
                    this.$emit('change', this.isShowInputLocal)
                })
            }
        },
        deleteComment(item) {
            const index = this.commentList.indexOf(item)
            this.commentList.splice(index, 1)
            deleteCommentApi({
                diaryId: this.diaryId,
                commentId: item.commentId,
            }).then(res => {
                console.log(res)
            })
        },
        toggleReplyInput(item) {
            const currentUserString = localStorage.getItem('currentUser')
            const currentUser = JSON.parse(currentUserString)

            if (item.userId === currentUser.userId) {
                console.log('删除')
            } else {
                if (item.commentId === this.showReplyInput) {
                    this.showReplyInput = null
                } else {
                    this.showReplyInput = item.commentId
                }
                this.newReply = ''
            }
        },
        addReply(item) {
            const reply = this.newReply.trim()
            if (reply !== '') {
                let content = reply
                // eslint-disable-next-line max-len
                const commentObj = { commentUser: this.currentUser.username, content, replyUser: item.userId, replyUsername: item.commentUser, userId: this.currentUser.userId, isReply: true }
                addCommentApi({
                    diaryId: this.diaryId,
                    ...commentObj,
                }).then(res => {
                    const replyComment = res.retVal
                    replyComment.commentUser = this.currentUser.username
                    replyComment.replyUsername = item.commentUser
                    replyComment.isReply = true
                    this.commentList.push(replyComment)
                    this.newReply = ''
                    this.showPicker = false
                    this.showReplyInput = null
                })
            }
        },
    },
}
</script>


<style scoped>
.comment-section {
  width: 100%;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  background-color: #f4f4f4;
}

.comments {
  margin-bottom: 2rem;
}

.comment-item {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.comment-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.comment-user {
  font-weight: bold;
  color: #161616;
}

.comment-details {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #888;
}

.comment-time,
.comment-ip {
  margin-left: 1rem;
}

.comment-content {
  padding: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  background-color: #fefefe;
}

.reply-to-info {
  font-size: 0.85rem;
  color: #666;
  margin-top: 0.25rem;
}

.reply-input,
.comment-input {
  padding: 1rem;
  background-color: #fefefe;
  border: 1px solid #d1d1d1;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

textarea {
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  resize: vertical;
  box-sizing: border-box; /* Added to include padding in width */
}

.emoji-picker-wrap {
  margin-bottom: 0.8rem;
  position: relative;
}

.emoji-picker-wrap button {
  background-color: #e4e4e4;
  color: #333;
}

.emoji-picker-wrap button:hover {
  background-color: #d3d3d3;
}

/* Add styles for mobile responsiveness */
@media (max-width: 768px) {
  .comment-info,
  .comment-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .comment-time,
  .comment-ip {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.reply-input button,
.comment-input button {
  /* Add a small right margin to the first button */
  margin-right: 8px; /* You can adjust this value as needed */
}

@media (max-width: 768px) {
  /* ... existing styles ... */
  .reply-input button,
  .comment-input button {
    /* On smaller screens, you might want to adjust or remove the margin */
    margin-right: 4px; /* Adjust as needed for mobile screens */
  }
}

</style>