<!-- eslint-disable max-len -->
<template>
        <div ref="list" v-auto-animate="{ duration: 100 }">
          <diary  v-for="(entry, index) in diaryList" :key="index" :diary="entry" @delete-diary="deleteDiary" />
          <Page :total="total" size="small" @on-change="change" show-elevator show-sizer style="margin-bottom: 45px;" @on-page-size-change="pageSizeCall" prev-text="上一页" next-text="下一页"/>
        </div>

</template>

<script>
import Diary from '@/components/diary/diaryEntity.vue'
import { diaryPage } from '@/api/diary'

export default {
    data() {
        return {
            diaryList: [],
            firstResult: 0,
            pageSize: 10,
            total: 0,
        }
    },
    components: {
        Diary,
    },
    mounted() {
        this.getDiaryList()
    },
    methods: {
        change(val) {
            this.firstResult = this.calculateStartData(val, this.pageSize)
            this.getDiaryList()
            const diaryList = this.$refs.list
            diaryList.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        pageSizeCall(val) {
            this.pageSize = val
        },
        calculateStartData(currentPage, pageSize) {
            let startData = (currentPage - 1) * pageSize
            return startData
        },
        deleteDiary(deletedDiaryId) {
            const index = this.diaryList.findIndex(diary => diary.diaryId === deletedDiaryId)
            if (index !== -1) {
                this.diaryList.splice(index, 1)
            }
        },
        getDiaryList() {
            diaryPage({
                isPublic: 'true',
                notShowMePublic: 'F',
                pageSize: this.pageSize,
                firstResult: this.firstResult,
            }).then(res => {
                this.diaryList = res.retVal.datas
                this.total = res.retVal.totalRows
            })
        },
    },
}
</script>

<style>

</style>