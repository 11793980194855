<template>
    <div class="SongList">
      <div class="covers" :style="{ display: minDisplay }">
        <div class="cover" v-for="(img, index) in imgs" :key="img">
          <img
            :src="img"
            width="90%"
            class="min"
            @click="zoomIn(index)"
            alt=""
          />
        </div>
      </div>
      <div class="max" :style="{ display: maxDisplay }">
        <div class="buttons">
          <button class="button" @click="zoomOut">收起</button>
          <button class="button" @click="rotateImage">旋转</button>
          <button class="button" @click="viewLargeImage">查看大图</button>
        </div>
        <div
          class="image-container"
          @click="zoomOut"
          v-for="(img, index) in imgs"
          :key="img"
          :class="[index === showIndex ? 'active' : 'None']"
        >
          <img :src="img" width="100%" :class="{ rotate: isRotated }" />
        </div>
        <div class="small">
          <div
            :class="[{ smallActive: index === showIndex }, 'cover-small']"
            v-for="(img, index) in imgs"
            :key="img"
            @click="select(index)"
          >
            <img :src="img" width="90%" />
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
    name: 'SongList',
    props: {
        imgs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showIndex: -1,
            isRotated: false,
        }
    },
    computed: {
        minDisplay() {
            return this.showIndex === -1 ? 'flex' : 'none'
        },
        maxDisplay() {
            return this.showIndex === -1 ? 'none' : 'block'
        },
    },
    methods: {
        getElementByIndex(arr, index) {
            if (index < 0 || index >= arr.length) {
                // 如果索引超出了数组的范围，则返回null或抛出错误
                return null
            }
            return arr[index]
        },
        zoomIn(i) {
            this.showIndex = i
        },
        zoomOut() {
            this.showIndex = -1
        },
        rotateImage() {
            this.isRotated = !this.isRotated
        },
        viewLargeImage() {
            const currentUrl = this.getElementByIndex(this.imgs, this.showIndex)
            this.$viewerApi({
                url: currentUrl,
                images: this.imgs,
            })
        },
        select(i) {
            this.showIndex = i
        },
    },
}
</script>

  <style scoped>
  .SongList {
    width: 40%;
  }

  .covers {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cover {
    display: flex;
    justify-content: center;
    width: 33%;
    margin: 10px 0;
  }

  .min {
    border-radius: 10px;
    cursor: zoom-in;
  }

  .max {
    cursor: zoom-out;
    width: 100%;
    overflow: hidden; /* 添加这行样式 */
  }

  .small {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cover-small {
    display: flex;
    justify-content: center;
    width: 10%;
    margin: 10px 0;
    opacity: 0.6;
    cursor: pointer;
  }

  .cover-small:hover {
    opacity: 1;
  }

  .active {
    display: flex;
  }

  .None {
    display: none;
  }

  .smallActive {
    opacity: 1;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
    color: #000; /* 文字颜色为黑色 */
    background-color: transparent; /* 背景色为透明 */
    border: 1px solid #000; /* 边框为黑色实线 */
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px; /* 按钮之间的右侧间距为5px */
  }

  .button:last-child {
    margin-right: 0; /* 最后一个按钮没有右侧间距 */
  }

  .button:hover {
    background-color: #000; /* 鼠标悬停时背景色变为黑色 */
    color: #fff; /* 文字颜色变为白色 */
  }

  .button:focus {
    outline: none;
  }
  </style>
