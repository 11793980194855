<template>
    <div>
      <Modal v-model="localVisible" title="用户信息" width="400" @on-visible-change="handleVisibleChange">
        <div class="user-info">
          <div class="avatar-section">
            <Avatar :src="userInfo.userHead" size="large"></Avatar>
            <div v-if="status" class="status">{{ status }}</div>
          </div>
          <div class="info-section">
            <p>用户名: <a style="color: inherit;text-decoration: none;" @click="goToUserProfile">{{ userInfo.username }}</a></p>
            <p>我有话说: <span class="motto">{{ userInfo.motto }}</span></p>
            <p>最后登录地址: {{ userInfo.lastLoginAddress }}</p>
            <p>最后登录时间: {{ userInfo.lastLogintime }}</p>
          </div>
        </div>
        <div slot="footer">
          <i-button @click="closeUserInfo">关闭</i-button>
        </div>
      </Modal>
    </div>
  </template>

<script>
import { Modal, Avatar, Button } from 'view-design'
import { getUserById } from '@/api/user.js'

export default {
    components: {
        Modal,
        Avatar,
        // eslint-disable-next-line vue/no-unused-components
        Button,
    },
    props: {
        userId: {
            type: String,
            default: '',
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        visible(newValue) {
            this.localVisible = newValue
        },
        localVisible(newValue) {
            if (newValue) {
                this.getUserInfo()
            }
            this.$emit('update:visible', newValue)
        },
    },
    data() {
        return {
            localVisible: this.visible,
            userInfo: {

            },
            status: '在线', // 添加状态字段
        }
    },
    mounted() {
    },
    methods: {
        getUserInfo() {
            getUserById({ userId: this.userId }).then(res => {
                this.userInfo = res.retVal
            })
        },
        handleVisibleChange(visible) {
            this.localVisible = visible
            if (!visible) {
                this.$emit('close', this.localVisible)
            }
        },
        closeUserInfo() {
            this.localVisible = false
            this.$emit('close', this.localVisible)
        },
        goToUserProfile() {
        // 跳转到用户个人主页的逻辑
        },
    },
}
</script>

<style scoped>
.user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px; /* 添加内边距 */
  background-color: #f5f5f5; /* 设置背景色 */
  border-radius: 10px; /* 添加圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.avatar-section {
  text-align: center;
  margin-right: 20px; /* 添加右侧间距 */
}

.status {
  color: #4CAF50;
  font-weight: bold;
  margin-top: 5px;
}

.info-section {
  flex: 1;
  padding-left: 20px;
}

.ivu-modal-footer {
  text-align: center;
}

/* 修改文字间距和大小 */
p {
  margin-bottom: 10px; /* 增加段落底部间距 */
  font-size: 16px; /* 调整字体大小 */
}

.motto {
  font-style: italic; /* 使用斜体字体 */
  font-size: 18px; /* 增加字体大小 */
  line-height: 1.5; /* 增加行高，使文字垂直居中 */
  color: #666; /* 修改字体颜色为灰色，更加柔和 */
}
</style>