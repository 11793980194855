<template>
  <div>
    <i-button @click="toggleLike" type="text">
      <Icon type="ios-heart" :class="{'liked': localIsLiked, 'not-liked': !localIsLiked}" />
      <span class="like-count">{{ localLikeCount }}</span>
    </i-button>
  </div>
</template>

<script>
import { addLike, cancelLike } from '@/api/diary.js'

export default {
    props: {
        // 父组件传递过来的参数
        diaryId: {
            type: String,
        },
        likeCount: {
            type: Number,
            default: 0,
        },
        isLiked: {
            type: Boolean,
            default: false,
        },
    },
    created() {
    },
    watch: {
        likeCount: {
            handler(newVal) {
                this.localLikeCount = newVal
            },
            immediate: true, // 在watcher创建时立即以当前值触发回调
        },
        isLiked: {
            handler(newVal) {
                this.localIsLiked = newVal
            },
            immediate: true,
        },
    },
    data() {
        return {
            localLikeCount: 0,
            localIsLiked: false,
        }
    },
    methods: {
        toggleLike() {
            this.localIsLiked = !this.localIsLiked
            this.localLikeCount += this.localIsLiked ? 1 : -1
            // 定义一个包含多个参数的对象
            let eventData = {
                likeNum: this.localLikeCount,
                isLike: this.localIsLiked,
            }

            // 通过$emit触发自定义事件，并传递参数对象
            this.$emit('like-count-change', eventData)
            if (this.localIsLiked) {
                addLike({ diaryId: this.diaryId }).then((res) => {
                    // Message.success({
                    //     content: res.retVal,
                    // })
                })
            } else {
                cancelLike({ diaryId: this.diaryId }).then((res) => {
                    // Message.success({
                    //     content: res.retVal,
                    // })
                })
            }
        },
    },
}
</script>

<style>
/* 未点赞时的样式 */
.not-liked {
  color: #666;
}

/* 点赞时的样式 */
.liked {
  color: #f00;
  background-color: #ffe6e6;
  box-shadow: 0 0 10px #f00;
  transform: scale(1.2);
  animation: likeAnimation 0.3s ease-in-out;
}

/* 点赞时的动画 */
@keyframes likeAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/* 点赞数量样式 */
.like-count {
  margin-left: 5px;
  font-size: 12px;
  color: #999;
}
</style>